//
//
//
//
//
//
//
//
//

export default {
    props: {
        variant: {
            type: String,
            default: 'secondary',
        },
    },
};
